/* You can add global styles to this file, and also import other style files */

// didn't want material ... sigh
@use '@angular/material' as mat;
@include mat.core();

@import './theme';

@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-2xl mb-4 mt-2;
}

h2 {
  @apply text-xl;
}

h3 {
  @apply text-lg;
}

a {
  @apply text-blue-600 underline;
}

button {
  @apply bg-gray-200 hover:bg-gray-400 text-black font-semibold hover:text-white px-1 border border-gray-400 hover:border-transparent rounded;
}

input {
  @apply border border-gray-400 px-1;
}

select {
  @apply border border-gray-400 px-1;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}

tr {
  @apply hover:bg-gray-100;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #40798c;
}
