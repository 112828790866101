@font-face {
  font-family: 'Nunito Sans';
  src: url('./NunitoSans-Variable.ttf') format('truetype');
  font-weight: 100 800;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./NunitoSans-Italic-Variable.ttf') format('truetype');
  font-weight: 100 800;
  font-style: italic;
}
